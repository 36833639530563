export enum LocationType {
  Hand = 1,
  HeirCard,
  PlayArea,
  PlayerTrickStack,
  PlayerColumns,
  Deck,
  PlayerHyena,
  HelpCard,
}
