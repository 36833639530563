export enum RuleId {
  ChooseCard = 1,
  SolveTrick,
  EndOfTurn,
  PrepareNewRound,
  ChoosePlayer,
  Rhinoceros,
  Snake,
  Cheetah,
  Eagle
}